$(document).on("click", '#select-all', function(event) {
    if(this.checked) {
        $(':checkbox').prop('checked', true);
    } else {
        $(':checkbox').prop('checked', false);
    }
});

$.fn.selectpicker.Constructor.BootstrapVersion = '5';
$('.bootstrap-select').selectpicker();


// Enables tooltips
[].slice.call($('[data-bs-toggle="tooltip"]')).map(function (el) {
  return new bootstrap.Tooltip(el)
});

$('.bi-copy').click(function(event) {
    var linkInput = $(this).parent().find('.copy-share-link');
    linkInput.select();
    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Failed to copy the link.');
    }
    $('#link-copied').removeClass("d-none")
    setTimeout(function() {
        $('#link-copied').fadeOut('slow', function() {
            $(this).addClass('d-none');
        });
    }, 1500);
});


function SelectPickerLazyLoad(elementQueryStr, pickerName="", initial=null) {
    var selectPage = 1;
    var selectPickerEl = $(elementQueryStr);
    var selectPickerItems = [];
    var selectPickerPagesLoaded = [];
    selectPickerEl.selectpicker();

    function loadPartyList (page=1) {
        if (page !== null && selectPickerPagesLoaded.includes(page) === false) {
          selectPickerPagesLoaded.push(page);
          $.ajax({
              url: `${selectPickerEl.data('url')}?page=${page}`,
              method: 'GET',
              success: successCallback,
          });
        }
    }

    function successCallback (data) {
        var selected = null;
        if ('items' in data) {
          selectPickerEl.empty();
          // Append options to the selectpicker
          data.items.forEach(function (item) {
            if (initial === item.id) {
                selected = item.id;
            }
            selectPickerItems.push(`<option value="${item.id}">${item.name}</option>`);
          });
          selectPage = data.page;
          selectPickerEl.html(selectPickerItems.join(''));
          selectPickerEl.selectpicker('refresh');
          if(selected !== null) {
            selectPickerEl.val(selected);
            selectPickerEl.selectpicker('refresh');
          }
        }
    }

    if (selectPickerEl.find('option').length <= 1) {
        selectPickerItems.push(`<option value="">All ${pickerName}</option>`);
        // selectPickerEl.html(`<option class="loading-options" disabled value="">Loading ${pickerName}</option>`);
        selectPickerEl.selectpicker('refresh');
        loadPartyList(selectPage);
    }

    selectPickerEl.on('shown.bs.select', function () {
        var dropdownMenu = selectPickerEl.parent().find('.dropdown-menu .inner');
        dropdownMenu.on('scroll', function () {
            if (dropdownMenu.scrollTop() + dropdownMenu.innerHeight() >= dropdownMenu[0].scrollHeight - 20) {
                loadPartyList(selectPage);
            }
        });

    });
}

function getQueryParams() {
  var params = new URLSearchParams(window.location.search);
  var paramsObj = {};

  for (var [key, value] of params.entries()) {
    paramsObj[key] = value;
  }

  return paramsObj;
}

// Redirect on change
$('.select-filter').on('changed.bs.select', function(e) {
    e.stopPropagation();
    var queryParams = getQueryParams();
    var filterItemsArray = [
        ['matter_type_id', 'select-matter-type-picker'],
        ['matter_id', 'select-matter-picker'],
        ['party_id', 'select-party-picker'],
        ['status', 'select-status-picker']
    ];
    for (var [hashKey, className] of filterItemsArray) {
        if($(this).hasClass(className) === true) {
            queryParams[hashKey] = this.value;
        }
        if("page" in queryParams) {
            delete queryParams["page"];
        }
    }
    var queryParamStr = new URLSearchParams(queryParams).toString();
    window.location.href = `${window.location.origin}${window.location.pathname}?${queryParamStr}`;
});